import scrollTop from '@/lib/common-service/scrollTop'
import getList from "@/lib/data-service/default/web_PurchaseOrder_buyerOrderList";
import cancel from '@/lib/data-service/default/web_PurchaseOrder_updatePurchaseOrderCancel'
import getAuditdetails from '@/lib/data-service/default/web_common_salesOrder_getAuditdetails'
import SelectCity from '../component/SelectCity'
import lookDepartInform from '@/lib/data-service/default/web_common_lookDepartInform'
import { setTimeFormat } from '../component/util'
export default {
  data() {
    return {
      searchForm: {
        currentPage: 1,
        pageSize: 10,
        productType: 2,
        collectGuestType: '',
        productAreasType: '',
        orderType: ''
      },
      setTimeFormat: setTimeFormat,
      regionalTypeList: [
        { label: '全部', value: '' },
        { label: '国内', value: '1' },
        { label: '出境', value: '2' },
      ],
      collectGuestTypeList: [
        { label: '全部', value: '' },
        { label: '散拼团', value: '1' },
        { label: '小包团', value: '2' },
      ],
      totalCount: 0,
      changeRecordList: [
        { text: '全部', value: '' },
        { text: '待支付', value: 2 },
        { text: '待确认', value: 1 },
        { text: '待确认出团', buyerState: 3, orderState: 4 },
        { text: '已确认出团', buyerState: 3, orderState: 5 },
        { text: '已出团', value: 4 },
        { text: '已回团', value: 5 },
        { text: '确认失败', value: 0 },
        { text: '待缴尾款', value: 10, type: '0' },
        { text: '已缴尾款', value: 22, type: '1' },
        { text: '已取消', value: 90, type: 'orderCancel' }
      ],
      orderTypes: [{ text: '全部', value: '' }, { text: '普通订单', value: '1' }, { text: '切位订单', value: '2' },
      { text: '特价订单', value: '3' }],
      NavItemActive: '',
      pageData: [],
      loading: false,
      selectedList: [],
      defaultState: 0
    };
  },
  components: { SelectCity },
  activated() {
    this.search()
  },
  methods: {
    statusStr(row) {
      let str
      if (row.orderCancel == 2) {
        str = '已取消'
      } else if (row.buyerState == 2) {
        str = '待支付'
      } else if (row.buyerState == 1) {
        str = '待确认'
      } else if (row.orderState == 4 && row.buyerState == 3) {
        str = '待确认出团'
      } else if (row.orderState == 5 && row.buyerState == 3) {
        str = '已确认出团'
      } else if (row.buyerState == 4) {
        str = '已出团'
      } else if (row.buyerState == 5) {
        str = '已回团'
      } else if (row.buyerState == 0) {
        str = '确认失败'
      } else if (row.finalPayment == 0 && row.buyerState == 3) {
        str = '待缴尾款'
      } else if (row.finalPayment == 1 && row.buyerState == 3) {
        str = '已缴尾款'
      } else if (row.buyerState == 6) {
        str = '申请退款'
      } else if (row.buyerState == 7) {
        str = '收客失败'
      } else if (row.buyerState == 8) {
        str = '退款失败'
      } else if (row.buyerState == 9) {
        str = '退款成功'
      }
      return str || '未知状态'
    },
    statuSearch(item, index) {
      this.resetSearchForm()

      this.NavItemActive = index
      let type = item.type
      type ?
        type == '0' ? (this.searchForm.finalPayment = item.type, this.searchForm.buyerState = item.value, this.searchForm.orderCancel = '') :
          type == '1' ? (this.searchForm.finalPayment = item.type, this.searchForm.buyerState = '', this.searchForm.orderCancel = '') :
            (this.searchForm.orderCancel = 2, this.searchForm.buyerState = '', this.searchForm.finalPayment = '') :
        (this.searchForm.buyerState = item.value, this.searchForm.finalPayment = '', this.searchForm.orderCancel = '')
      this.searchForm.currentPage = 1

      if (item.orderState != undefined) {
        this.searchForm.orderState = item.orderState
      }

      if (item.buyerState != undefined) {
        this.searchForm.buyerState = item.buyerState
      }

      this.search()
    },
    resetSearchForm() {
      this.searchForm = {
        currentPage: 1,
        pageSize: 10,
        productType: 2,
        collectGuestType: '',
        productAreasType: '',
        orderType: '',
      }
    },
    getRegiment(val) {
      if (!val) {
        this.searchForm.departtureDateStart = ''
        this.searchForm.departtureDateEnd = ''
      } else {
        this.searchForm.departtureDateStart = val[0]
        this.searchForm.departtureDateEnd = val[1]
      }
    },
    getPlaceOrder(val) {
      if (!val) {
        this.searchForm.orderDateStart = ''
        this.searchForm.orderDateEnd = ''
      } else {
        this.searchForm.orderDateStart = val[0]
        this.searchForm.orderDateEnd = val[1]
      }
    },
    selectedCity(val) {
      let data = this.searchForm
      val.selectType == 'start' ?
        (data.fromRegion = val.area, data.provinceOption = val.province, data.setOutCityId = val.city) :
        (data.destinationCountry = val.area, data.destinationProvince = val.province, data.destinationCity = val.city)
    },
    reset() {

      this.searchForm = { currentPage: 1, pageSize: 10, collectGuestType: '', productAreasType: '' }
      this.defaultState++
      this.search()
    },
    search() {
      let data = this.searchForm
      getList(data).then(res => {
        this.pageData = res.pageResult.pageData
        this.totalCount = res.pageResult.totalCount
      })
    },
    setOrdersNo(item, type) {
      switch (type) {
        case 'viewOrder':
          //查看订单
          this.$router.push({ name: 'distributor-tour-back-tour-order-detail', query: { id: item.id }, params: { refresh: 1 } })
          break;
        case 'viewAffirm':
          //查看确认单
          this._setPopPu('下载确认单吗？', () => { window.open(item.platformBuyerAffirm) })
          break;
        case 'viewAdvice':
          //查看出团通知单
          this._lookDepartInform(item)
          break;
        case 'cancelOrder':
          //取消订单
          this._cancelOrder(item)
          break;
        case 'viewCause':
          //查看原因
          this._getAuditdetails(item)
          break;
        case 'unpaid':
          //待缴尾款
          this.$router.push({
            name: 'distributor-tour-back-tour-order-detail', query: {
              id: item.id, type: 'pay', payType: '2',
              superior: 'distributor-tour-back-tour-independent-order-list'
            }, params: { refresh: 1 }
          })
          break;
        case 'pay':
          //支付
          this.$router.push({
            name: 'distributor-tour-back-tour-order-detail', query: {
              id: item.id, type: 'pay', payType: '1',
              superior: 'distributor-tour-back-tour-independent-order-list'
            }, params: { refresh: 1 }
          })
          break;
        default:
          break;
      }
    },
    SelectionChange(val) {
      this.selectedList = val
    },
    batchPay() {

    },
    _lookDepartInform(val) {
      lookDepartInform({ orderId: val.id }).then(res => {
        res.code == this.SUCCESS_CODE ?
          window.open(res.url) : ''
      })
    },
    _getAuditdetails(val) {
      getAuditdetails({ id: val.id }).then(res => {
        this.$alert(res.platformAuditContents || res.supplierAuditContents, '失败原因', {
          confirmButtonText: '确定',
          callback: action => {
          }
        });
      })
    },
    _cancelOrder(val) {
      let data = {
        "id": val.id,
        "orderCancel": 2
      }
      this._setPopPu('确定要取消订单吗？', () => {
        cancel(data).then(res => {
          this.$message({ type: "warning", message: "取消订单成功！" })
          this.search()

        })
      })
    },
    _setPopPu(text, callBack) {
      const content = {
        text: text || ''
      }
      this.$confirm(content.text, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        return callBack()
      }).catch(() => { })
    },
    handlerSize(val) {
      this.searchForm.pageSize = val
      this.search()
    },
    handlerPage(val) {
      this.searchForm.currentPage = val
      this.search()
    }
  }
};
